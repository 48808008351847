@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 300;
  src: local(""), url("ibm-plex-sans-v14-latin-300.b62a4e69.woff2") format("woff2"), url("ibm-plex-sans-v14-latin-300.7199e2f6.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("ibm-plex-sans-v14-latin-regular.791aef38.woff2") format("woff2"), url("ibm-plex-sans-v14-latin-regular.dc4cb301.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  src: local(""), url("ibm-plex-sans-v14-latin-500.d5c65758.woff2") format("woff2"), url("ibm-plex-sans-v14-latin-500.f8c71eff.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  src: local(""), url("ibm-plex-sans-v14-latin-600.f578fd79.woff2") format("woff2"), url("ibm-plex-sans-v14-latin-600.b2bc36af.woff") format("woff");
}

html, body {
  font-family: IBM Plex Sans, sans-serif !important;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

body {
  --tw-text-opacity: 1;
  color: rgb(35 35 35 / var(--tw-text-opacity));
  font-size: 1rem;
}

.tdbv2 * {
  border-style: solid;
}

.tdbv2 p {
  padding-bottom: 0;
}

h1 {
  font-size: 1.5rem;
}

@media (width >= 1024px) {
  h1 {
    font-size: 3rem;
  }
}

h1 {
  font-weight: 600;
  line-height: 1.375;
}

h2 {
  font-size: 1.5rem;
}

@media (width >= 1024px) {
  h2 {
    font-size: 2.5rem;
  }
}

h2 {
  font-weight: 500;
  line-height: 1.25;
}

h2, h3, h4 {
  padding: 0;
}

.tdb-title-with-border {
  --tw-border-opacity: 1;
  border-style: solid;
  border-bottom-width: 1px;
  border-color: rgb(133 91 250 / var(--tw-border-opacity));
  padding-bottom: 2px;
}

@media (width >= 1024px) {
  .tdb-flex-container {
    width: 1024px;
    margin: auto;
  }
}

.tdb-flex-container {
  flex-direction: row;
  display: flex;
}

@media (width >= 1024px) {
  .tdb-container {
    width: 1024px;
    margin: auto;
  }
}

.tdb-box-config {
  padding: 2rem 1rem;
}

@media (width >= 1024px) {
  .tdb-box-config {
    padding: 0;
  }
}

.tdb-button-purple-gradiant {
  text-align: center;
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #855bfa var(--tw-gradient-from-position);
  --tw-gradient-to: #855bfa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #9daefd var(--tw-gradient-to-position);
  border-width: 2px;
  border-radius: .75rem;
  padding: .75rem 2rem;
  font-size: 1rem;
  font-weight: 700;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tdb-button-purple-gradiant:hover {
  --tw-gradient-from: #6746c2 var(--tw-gradient-from-position);
  --tw-gradient-to: #6746c200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #7885c3 var(--tw-gradient-to-position);
}

.tdb-button-purple-gradiant {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-width: 0;
}

.tdb-button-purple-inverted {
  text-align: center;
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(133 91 250 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: .75rem;
  padding: .75rem 2rem;
  font-size: 1rem;
  font-weight: 700;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tdb-button-purple-inverted:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(133 91 250 / var(--tw-bg-opacity));
}

.tdb-button-purple-inverted {
  --tw-text-opacity: 1;
  color: rgb(133 91 250 / var(--tw-text-opacity));
}

.tdb-button-purple-inverted:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.tdb-button-white {
  text-align: center;
  border-width: 2px;
  border-radius: .75rem;
  padding: .75rem 2rem;
  font-size: 1rem;
  font-weight: 700;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tdb-button-white:hover {
  --tw-border-opacity: 1;
  border-color: rgb(133 91 250 / var(--tw-border-opacity));
}

.tdb-button-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.tdb-button-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(133 91 250 / var(--tw-bg-opacity));
}

.tdb-button-white {
  --tw-text-opacity: 1;
  color: rgb(133 91 250 / var(--tw-text-opacity));
}

.tdb-button-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.tdb-button-white {
  --tw-drop-shadow: drop-shadow(0 5px 17px #0003);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.tdb-link {
  --tw-text-opacity: 1;
  color: rgb(133 91 250 / var(--tw-text-opacity));
  text-decoration-line: none;
}

input {
  accent-color: #855bfa;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

footer h3 {
  font-size: 1rem;
}

.bottom-purple-border {
  position: relative;
}

.bottom-purple-border:after {
  --tw-content: "";
  content: var(--tw-content);
  width: 100%;
  height: .5rem;
  position: absolute;
  bottom: -.5rem;
  left: 0;
}

@media (width >= 1024px) {
  .bottom-purple-border:after {
    content: var(--tw-content);
    height: 1rem;
    bottom: -1rem;
  }
}

.bottom-purple-border:after {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #855bf9 var(--tw-gradient-from-position);
  --tw-gradient-to: #855bf900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  content: var(--tw-content);
  --tw-gradient-to: #9caefd var(--tw-gradient-to-position);
}

@media (width >= 1024px) {
  .bottom-purple-border {
    margin-bottom: 1rem;
  }
}

.glider-contain .glider-prev {
  background-image: url("carousel-arrow-left.ea88d4fb.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  width: 72px;
  height: 72px;
  margin-left: -3rem;
}

.glider-contain .glider-next {
  background-image: url("carousel-arrow-right.9b6d3aad.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  width: 72px;
  height: 72px;
  margin-right: -3rem;
}

.glider-contain .glider-dot {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(133 91 250 / var(--tw-border-opacity));
  width: 8px;
  height: 8px;
}

.glider-contain .glider-dot.active {
  --tw-bg-opacity: 1;
  background-color: rgb(133 91 250 / var(--tw-bg-opacity));
}

.blog .glider-contain {
  width: 100%;
}

.blog .glider {
  width: 100%;
  height: 493px;
}

@media (width >= 1024px) {
  .blog .glider {
    height: 548px;
  }
}

.blog .glider {
  flex-direction: row;
  align-content: space-between;
  display: flex;
}

.blog .post {
  position: relative;
}

@media (width >= 1024px) {
  .blog .post {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
}

.blog .post-body {
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 140px 1.5rem 2rem;
}

@media (width >= 1024px) {
  .blog .post-body {
    margin-left: 0;
    margin-right: 0;
    padding-top: 196px;
  }
}

.blog .post-body {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 1.5rem;
  position: relative;
  overflow: hidden;
}

.blog .post-subtitle {
  --tw-text-opacity: 1;
  color: rgb(133 91 250 / var(--tw-text-opacity));
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0;
  font-size: 1rem;
}

.blog .post-title {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: .75rem;
  padding: 0;
  font-size: 23px;
  line-height: 2rem;
  display: -webkit-box;
  overflow-y: hidden;
}

.blog .post-thumbnail {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.blog .post-snippet {
  height: 6rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.375;
}

.blog .post-snippet > div {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow-y: hidden;
}

.blog .post-button {
  text-align: center;
  width: 100%;
  display: block;
}

.artigos .tdb-carousel-item, .midia .tdb-carousel-item {
  --tw-shadow: 0 4px 8px #0003;
  --tw-shadow-colored: 0 4px 8px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 1.5rem;
  height: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 2rem 1.5rem;
  line-height: 1.5rem;
  position: relative;
}

.artigos .tdb-carousel-item a, .midia .tdb-carousel-item a {
  display: block;
}

.artigos .tdb-carousel-item a span, .midia .tdb-carousel-item a span {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-bottom: .75rem;
  display: -webkit-box;
  overflow-y: hidden;
}

.artigos .tdb-carousel-item cite, .midia .tdb-carousel-item cite {
  --tw-text-opacity: 1;
  color: rgb(133 91 250 / var(--tw-text-opacity));
  font-style: normal;
}

.midia .tdb-carousel-item a {
  margin-bottom: 0;
}

.faq-item .faq-item-description, .doencas-item .doencas-item-description {
  transition: max-height .2s ease-out;
}

.faq-item, .faq-item[aria-expanded="false"], .doencas-item, .doencas-item[aria-expanded="false"] {
  position: relative;
}

.faq-item:after, .faq-item[aria-expanded="false"]:after, .doencas-item:after, .doencas-item[aria-expanded="false"]:after {
  --tw-content: "";
  content: var(--tw-content);
  width: 18px;
  height: 11px;
  margin-right: 1rem;
  position: absolute;
  top: 1.82rem;
  right: .3rem;
}

@media (width >= 1024px) {
  .faq-item:after, .faq-item[aria-expanded="false"]:after, .doencas-item:after, .doencas-item[aria-expanded="false"]:after {
    content: var(--tw-content);
    top: 2rem;
  }
}

.faq-item:after, .faq-item[aria-expanded="false"]:after, .doencas-item:after, .doencas-item[aria-expanded="false"]:after {
  content: var(--tw-content);
  background-image: url("row-arrow.cb29627d.svg");
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.faq-item[aria-expanded="true"]:after, .doencas-item[aria-expanded="true"]:after {
  content: var(--tw-content);
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.faq-item[aria-expanded="true"] .faq-item-description, .doencas-item[aria-expanded="true"] .faq-item-description {
  border-bottom-width: 2px;
}

.doencas-item:after, .doencas-item[aria-expanded="false"]:after {
  content: var(--tw-content);
  top: 2rem;
}

.medico-item .item-paragraphs {
  transition: max-height .2s ease-out;
}

@media (width <= 1023px) {
  .medico-item .item-avatar, .medico-item[aria-expanded="false"] .item-avatar {
    position: relative;
  }

  .medico-item .item-avatar:after, .medico-item[aria-expanded="false"] .item-avatar:after {
    --tw-content: "";
    content: var(--tw-content);
    background-image: url("row-arrow.cb29627d.svg");
    width: 18px;
    height: 11px;
    margin-right: 1rem;
    transition-property: transform;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    position: absolute;
    bottom: 1.3rem;
    right: .3rem;
  }

  .medico-item[aria-expanded="true"] .item-avatar:after {
    content: var(--tw-content);
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .medico-item[aria-expanded="true"] .item-avatar .item-description {
    border-bottom-width: 2px;
  }
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.-left-\[24\] {
  left: -24px;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.top-\[450\] {
  top: 450px;
}

.z-50 {
  z-index: 50;
}

.-order-1 {
  order: -1;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-16 {
  height: 4rem;
}

.h-\[17\] {
  height: 17px;
}

.h-\[210\] {
  height: 210px;
}

.h-\[22px\] {
  height: 22px;
}

.h-\[25px\] {
  height: 25px;
}

.h-\[424\] {
  height: 424px;
}

.h-\[44px\] {
  height: 44px;
}

.h-\[45\], .h-\[45px\] {
  height: 45px;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[72\], .h-\[72px\] {
  height: 72px;
}

.h-\[73px\] {
  height: 73px;
}

.h-\[75px\] {
  height: 75px;
}

.h-\[80\] {
  height: 80px;
}

.h-\[calc\(100vh_-_107px\)\] {
  height: calc(100vh - 107px);
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-0 {
  max-height: 0;
}

.w-2\/12 {
  width: 16.6667%;
}

.w-5\/12 {
  width: 41.6667%;
}

.w-\[110\] {
  width: 110px;
}

.w-\[135px\] {
  width: 135px;
}

.w-\[140px\] {
  width: 140px;
}

.w-\[145px\] {
  width: 145px;
}

.w-\[164px\] {
  width: 164px;
}

.w-\[228px\] {
  width: 228px;
}

.w-\[241px\] {
  width: 241px;
}

.w-\[24\] {
  width: 24px;
}

.w-\[25px\] {
  width: 25px;
}

.w-\[33px\] {
  width: 33px;
}

.w-\[45px\] {
  width: 45px;
}

.w-\[55\%\] {
  width: 55%;
}

.w-\[80\] {
  width: 80px;
}

.w-full {
  width: 100%;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-xl {
  max-width: 36rem;
}

.flex-auto {
  flex: auto;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.basis-1\/4 {
  flex-basis: 25%;
}

.basis-2\/4 {
  flex-basis: 50%;
}

.basis-3\/12 {
  flex-basis: 25%;
}

.basis-5\/12 {
  flex-basis: 41.6667%;
}

.basis-6\/12 {
  flex-basis: 50%;
}

.basis-7\/12 {
  flex-basis: 58.3333%;
}

.basis-8\/12 {
  flex-basis: 66.6667%;
}

.basis-9\/12 {
  flex-basis: 75%;
}

.border-collapse {
  border-collapse: collapse;
}

.cursor-pointer {
  cursor: pointer;
}

.list-decimal {
  list-style-type: decimal;
}

.list-disc {
  list-style-type: disc;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.overflow-hidden {
  overflow: hidden;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-\[1px\] {
  border-bottom-width: 1px;
}

.border-b-\[3px\] {
  border-bottom-width: 3px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-solid {
  border-style: solid;
}

.border-gray {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-border-opacity));
}

.border-graystronger {
  --tw-border-opacity: 1;
  border-color: rgb(169 169 169 / var(--tw-border-opacity));
}

.border-purple {
  --tw-border-opacity: 1;
  border-color: rgb(133 91 250 / var(--tw-border-opacity));
}

.border-purpulelighter {
  --tw-border-opacity: 1;
  border-color: rgb(201 188 236 / var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-b-gray {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(229 229 229 / var(--tw-border-opacity));
}

.bg-\[\#fcfcfc\] {
  --tw-bg-opacity: 1;
  background-color: rgb(252 252 252 / var(--tw-bg-opacity));
}

.bg-\[url\] {
  background-color: url;
}

.bg-gray {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.bg-graylighter {
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 239 / var(--tw-bg-opacity));
}

.bg-rose {
  --tw-bg-opacity: 1;
  background-color: rgb(237 230 254 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-\[url\(\'\.\.\/src\/img\/backgrounds\/dna-mobile\.svg\'\)\] {
  background-image: url("dna-mobile.a8904f8c.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/backgrounds\/dna-vertical\.svg\'\)\] {
  background-image: url("dna-vertical.96dfe8e6.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/elements\/purple-timeline-mobile\.svg\'\)\] {
  background-image: url("purple-timeline-mobile.587ca193.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/burgermenu-closed\.svg\'\)\] {
  background-image: url("burgermenu-closed.117b45a4.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/doencas-cardiovasculares-e-dislipidemias\.svg\'\)\] {
  background-image: url("doencas-cardiovasculares-e-dislipidemias.9be156bf.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/doencas-deficiencias_do_metabolismo\.svg\'\)\] {
  background-image: url("doencas-deficiencias_do_metabolismo.567c2026.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/doencas-doencas_endocrinas\.svg\'\)\] {
  background-image: url("doencas-doencas_endocrinas.dec7fbd0.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/doencas-doencas_esqueleticas\.svg\'\)\] {
  background-image: url("doencas-doencas_esqueleticas.fb841fbd.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/doencas-doencas_hematologicas\.svg\'\)\] {
  background-image: url("doencas-doencas_hematologicas.132f4f73.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/doencas-doencas_hepaticas_gastrointestinais\.svg\'\)\] {
  background-image: url("doencas-doencas_hepaticas_gastrointestinais.10531351.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/doencas-doencas_imunologicas\.svg\'\)\] {
  background-image: url("doencas-doencas_imunologicas.1fab9c59.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/doencas-doencas_neurologicas\.svg\'\)\] {
  background-image: url("doencas-doencas_neurologicas.4298861f.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/doencas-doencas_pulmonares\.svg\'\)\] {
  background-image: url("doencas-doencas_pulmonares.cff1d299.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/doencas-doencas_renais\.svg\'\)\] {
  background-image: url("doencas-doencas_renais.e914ad9b.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/doencas-erros_inatos_do_metabolismo\.svg\'\)\] {
  background-image: url("doencas-erros_inatos_do_metabolismo.007658d6.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/doencas-neoplasias\.svg\'\)\] {
  background-image: url("doencas-neoplasias.9f5fad06.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/doencas-surdez\.svg\'\)\] {
  background-image: url("doencas-surdez.f4dc0c0f.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/doencas_farmacogenetica\.svg\'\)\] {
  background-image: url("doencas_farmacogenetica.de39a770.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/i-small\.png\'\)\] {
  background-image: url("i-small.22e652e7.png");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/icon-arrow-right\.png\'\)\] {
  background-image: url("icon-arrow-right.754f640f.png");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/icone-abordagem-nacional\.svg\'\)\] {
  background-image: url("icone-abordagem-nacional.9ae346e2.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/icone-america-sul\.svg\'\)\] {
  background-image: url("icone-america-sul.5081b2a5.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/icone-award\.svg\'\)\] {
  background-image: url("icone-award.96637294.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/icone-check\.svg\'\)\] {
  background-image: url("icone-check.49d51e74.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/icone-coleta\.svg\'\)\] {
  background-image: url("icone-coleta.f3946866.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/icone-laudo\.svg\'\)\] {
  background-image: url("icone-laudo.38596087.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/icone-medico\.svg\'\)\] {
  background-image: url("icone-medico.c445a2fc.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/icone-remedios\.svg\'\)\] {
  background-image: url("icone-remedios.12fae191.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/icone-rosto-de-bebe\.svg\'\)\] {
  background-image: url("icone-rosto-de-bebe.f4c76432.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/icone-simbolo-alerta\.svg\'\)\] {
  background-image: url("icone-simbolo-alerta.dbb12086.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/icons\/icone-supporte\.svg\'\)\] {
  background-image: url("icone-supporte.0020dbd8.svg");
}

.bg-\[url\(\'\.\.\/src\/img\/pictures\/banner-mobile-home-bebe\.jpeg\'\)\] {
  background-image: url("banner-mobile-home-bebe.787ae494.jpeg");
}

.bg-\[url\(\'\.\.\/src\/img\/pictures\/resultados-bebe-mobile\.jpg\'\)\] {
  background-image: url("resultados-bebe-mobile.21e15426.jpg");
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.from-\[\#855bf9\] {
  --tw-gradient-from: #855bf9 var(--tw-gradient-from-position);
  --tw-gradient-to: #855bf900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#865afb\] {
  --tw-gradient-from: #865afb var(--tw-gradient-from-position);
  --tw-gradient-to: #865afb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#9571fa\] {
  --tw-gradient-from: #9571fa var(--tw-gradient-from-position);
  --tw-gradient-to: #9571fa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#9cabfd\] {
  --tw-gradient-from: #9cabfd var(--tw-gradient-from-position);
  --tw-gradient-to: #9cabfd00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-\[\#865afb\] {
  --tw-gradient-to: #865afb var(--tw-gradient-to-position);
}

.to-\[\#9cabfd\] {
  --tw-gradient-to: #9cabfd var(--tw-gradient-to-position);
}

.to-\[\#9caefd\] {
  --tw-gradient-to: #9caefd var(--tw-gradient-to-position);
}

.to-\[\#aab8fd\] {
  --tw-gradient-to: #aab8fd var(--tw-gradient-to-position);
}

.bg-contain {
  background-size: contain;
}

.bg-cover {
  background-size: cover;
}

.bg-\[left_\.5em_top_50\%\] {
  background-position: .5em 50%;
}

.bg-\[left_\.75em_top_50\%\] {
  background-position: .75em 50%;
}

.bg-\[left_0\.65rem_top_0rem\] {
  background-position: .65rem 0;
}

.bg-\[left_0_top_1em\] {
  background-position: 0 1em;
}

.bg-\[left_0_top_48px\] {
  background-position: 0 48px;
}

.bg-\[left_1\.2rem_top_50\%\] {
  background-position: 1.2rem 50%;
}

.bg-\[left_1\.5em_top_50\%\] {
  background-position: 1.5em 50%;
}

.bg-\[left_1\.5rem_top_50\%\] {
  background-position: 1.5rem 50%;
}

.bg-\[left_1\.6em_top_50\%\] {
  background-position: 1.6em 50%;
}

.bg-\[left_1\.75rem_top_50\%\] {
  background-position: 1.75rem 50%;
}

.bg-\[left_1em_top_50\%\] {
  background-position: 1em 50%;
}

.bg-\[right_1em_top_0\] {
  background-position: right 1em top 0;
}

.bg-bottom {
  background-position: bottom;
}

.bg-right {
  background-position: 100%;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.\!p-4 {
  padding: 1rem !important;
}

.\!p-6 {
  padding: 1.5rem !important;
}

.p-0 {
  padding: 0;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-\[\.5rem_1rem_\.5rem_5rem\] {
  padding: .5rem 1rem .5rem 5rem;
}

.p-\[1\.5em_3em_1\.5em_5\.5em\] {
  padding: 1.5em 3em 1.5em 5.5em;
}

.p-\[1rem_1rem_1rem_6rem\] {
  padding: 1rem 1rem 1rem 6rem;
}

.p-\[2rem_1rem_0\] {
  padding: 2rem 1rem 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-\[72\] {
  padding-top: 72px;
  padding-bottom: 72px;
}

.\!pb-8 {
  padding-bottom: 2rem !important;
}

.\!pt-20 {
  padding-top: 5rem !important;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-28 {
  padding-top: 7rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-\[72\] {
  padding-top: 72px;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-\[112px\] {
  font-size: 112px;
}

.text-\[23px\] {
  font-size: 23px;
}

.text-base {
  font-size: 1rem;
}

.text-h3 {
  font-size: 1.75rem;
}

.text-larger {
  font-size: 1.25rem;
}

.text-lg {
  font-size: 1.125rem;
}

.text-nano {
  font-size: .6rem;
}

.text-smaller {
  font-size: .875rem;
}

.text-tiny {
  font-size: .8125rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-thin {
  font-weight: 100;
}

.uppercase {
  text-transform: uppercase;
}

.normal-case {
  text-transform: none;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-none {
  line-height: 1;
}

.leading-snug {
  line-height: 1.375;
}

.leading-tight {
  line-height: 1.25;
}

.text-\[\#41B053\] {
  --tw-text-opacity: 1;
  color: rgb(65 176 83 / var(--tw-text-opacity));
}

.text-\[\#b0414e\] {
  --tw-text-opacity: 1;
  color: rgb(176 65 78 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-graystronger {
  --tw-text-opacity: 1;
  color: rgb(169 169 169 / var(--tw-text-opacity));
}

.text-purple {
  --tw-text-opacity: 1;
  color: rgb(133 91 250 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.placeholder-white::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(255 255 255 / var(--tw-placeholder-opacity));
}

@media (width >= 1024px) {
  @media (width >= 1024px) {
    .lg\:tdb-flex-container {
      width: 1024px;
      margin: auto;
    }
  }

  .lg\:tdb-flex-container {
    flex-direction: row;
    display: flex;
  }
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:top-\[-20px\]:before {
  content: var(--tw-content);
  top: -20px;
}

.before\:mr-4:before {
  content: var(--tw-content);
  margin-right: 1rem;
}

.before\:inline-block:before {
  content: var(--tw-content);
  display: inline-block;
}

.before\:h-\[32\]:before {
  content: var(--tw-content);
  height: 32px;
}

.before\:h-\[40\]:before {
  content: var(--tw-content);
  height: 40px;
}

.before\:min-h-\[16\]:before {
  content: var(--tw-content);
  min-height: 16px;
}

.before\:w-\[32\]:before {
  content: var(--tw-content);
  width: 32px;
}

.before\:w-\[40\]:before {
  content: var(--tw-content);
  width: 40px;
}

.before\:min-w-\[16\]:before {
  content: var(--tw-content);
  min-width: 16px;
}

.before\:bg-\[\#4A75AC\]:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(74 117 172 / var(--tw-bg-opacity));
}

.before\:bg-\[\#B6C457\]:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(182 196 87 / var(--tw-bg-opacity));
}

.before\:bg-\[\#D53421\]:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(213 52 33 / var(--tw-bg-opacity));
}

.before\:bg-\[\#EFEC3D\]:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(239 236 61 / var(--tw-bg-opacity));
}

.before\:bg-\[url\(\'\.\.\/src\/img\/icons\/check\.png\'\)\]:before {
  content: var(--tw-content);
  background-image: url("check.cb9f01cd.png");
}

.before\:bg-\[url\(\'\.\.\/src\/img\/icons\/i-purple\.png\'\)\]:before {
  content: var(--tw-content);
  background-image: url("i-purple.3b41cb92.png");
}

.before\:align-middle:before {
  content: var(--tw-content);
  vertical-align: middle;
}

.before\:content-\[\'\'\]:before {
  --tw-content: "";
  content: var(--tw-content);
}

.after\:block:after {
  content: var(--tw-content);
  display: block;
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

@media (width <= 1023px) {
  .sm\:relative {
    position: relative;
  }

  .sm\:-top-\[184\] {
    top: -184px;
  }

  .sm\:top-\[280\] {
    top: 280px;
  }

  .sm\:order-1 {
    order: 1;
  }

  .sm\:order-2 {
    order: 2;
  }

  .sm\:float-left {
    float: left;
  }

  .sm\:clear-left {
    clear: left;
  }

  .sm\:m-\[0_1rem_1rem\] {
    margin: 0 1rem 1rem;
  }

  .sm\:mb-12 {
    margin-bottom: 3rem;
  }

  .sm\:mb-2 {
    margin-bottom: .5rem;
  }

  .sm\:mb-4 {
    margin-bottom: 1rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:h-\[120\] {
    height: 120px;
  }

  .sm\:max-h-0 {
    max-height: 0;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:grow {
    flex-grow: 1;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:flex-col {
    flex-direction: column;
  }

  .sm\:items-end {
    align-items: flex-end;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:justify-center {
    justify-content: center;
  }

  .sm\:gap-2 {
    gap: .5rem;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:overflow-hidden {
    overflow: hidden;
  }

  .sm\:rounded-2xl {
    border-radius: 1rem;
  }

  .sm\:bg-graylighter {
    --tw-bg-opacity: 1;
    background-color: rgb(239 239 239 / var(--tw-bg-opacity));
  }

  .sm\:bg-\[50\%_1\.1rem\] {
    background-position: 50% 1.1rem;
  }

  .sm\:p-4 {
    padding: 1rem;
  }

  .sm\:p-\[1\.6em_0_3\.3rem_9\.4em\] {
    padding: 1.6em 0 3.3rem 9.4em;
  }

  .sm\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .sm\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .sm\:\!pb-4 {
    padding-bottom: 1rem !important;
  }

  .sm\:\!pb-8 {
    padding-bottom: 2rem !important;
  }

  .sm\:pb-0 {
    padding-bottom: 0;
  }

  .sm\:pr-10 {
    padding-right: 2.5rem;
  }

  .sm\:text-center {
    text-align: center;
  }

  .sm\:text-base {
    font-size: 1rem;
  }

  .sm\:font-semibold {
    font-weight: 600;
  }

  .sm\:leading-snug {
    line-height: 1.375;
  }

  .sm\:after\:content-none:after {
    --tw-content: none;
    content: var(--tw-content);
  }
}

@media (width >= 1024px) {
  .lg\:absolute {
    position: absolute;
  }

  .lg\:relative {
    position: relative;
  }

  .lg\:left-0 {
    left: 0;
  }

  .lg\:top-0, .lg\:top-\[0\] {
    top: 0;
  }

  .lg\:top-\[72\] {
    top: 72px;
  }

  .lg\:-z-10 {
    z-index: -10;
  }

  .lg\:order-1 {
    order: 1;
  }

  .lg\:order-2 {
    order: 2;
  }

  .lg\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .lg\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .lg\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .lg\:-ml-28 {
    margin-left: -7rem;
  }

  .lg\:-mt-11 {
    margin-top: -2.75rem;
  }

  .lg\:-mt-14 {
    margin-top: -3.5rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mb-12 {
    margin-bottom: 3rem;
  }

  .lg\:mb-20 {
    margin-bottom: 5rem;
  }

  .lg\:mb-4 {
    margin-bottom: 1rem;
  }

  .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:ml-16 {
    margin-left: 4rem;
  }

  .lg\:ml-4 {
    margin-left: 1rem;
  }

  .lg\:ml-48 {
    margin-left: 12rem;
  }

  .lg\:mr-6 {
    margin-right: 1.5rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mt-12 {
    margin-top: 3rem;
  }

  .lg\:mt-16 {
    margin-top: 4rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline-block {
    display: inline-block;
  }

  .lg\:inline {
    display: inline;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-20 {
    height: 5rem;
  }

  .lg\:h-\[104\], .lg\:h-\[104px\] {
    height: 104px;
  }

  .lg\:h-\[111px\] {
    height: 111px;
  }

  .lg\:h-\[200\] {
    height: 200px;
  }

  .lg\:h-\[237\] {
    height: 237px;
  }

  .lg\:h-\[248\] {
    height: 248px;
  }

  .lg\:h-\[352\] {
    height: 352px;
  }

  .lg\:h-\[392\] {
    height: 392px;
  }

  .lg\:h-\[650\] {
    height: 650px;
  }

  .lg\:h-\[690px\] {
    height: 690px;
  }

  .lg\:h-\[69px\] {
    height: 69px;
  }

  .lg\:h-\[710px\] {
    height: 710px;
  }

  .lg\:h-auto {
    height: auto;
  }

  .lg\:h-full {
    height: 100%;
  }

  .lg\:\!w-\[425\] {
    width: 425px !important;
  }

  .lg\:w-\[104px\] {
    width: 104px;
  }

  .lg\:w-\[167px\] {
    width: 167px;
  }

  .lg\:w-\[200\] {
    width: 200px;
  }

  .lg\:w-\[248\] {
    width: 248px;
  }

  .lg\:w-\[288\] {
    width: 288px;
  }

  .lg\:w-\[422\] {
    width: 422px;
  }

  .lg\:w-\[480px\] {
    width: 480px;
  }

  .lg\:w-\[512p\] {
    width: 512p;
  }

  .lg\:w-\[600\] {
    width: 600px;
  }

  .lg\:w-\[67px\] {
    width: 67px;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:max-w-\[70\%\] {
    max-width: 70%;
  }

  .lg\:max-w-\[80\%\] {
    max-width: 80%;
  }

  .lg\:max-w-\[85\%\] {
    max-width: 85%;
  }

  .lg\:basis-1\/2 {
    flex-basis: 50%;
  }

  .lg\:basis-4\/12 {
    flex-basis: 33.3333%;
  }

  .lg\:basis-5\/12 {
    flex-basis: 41.6667%;
  }

  .lg\:basis-6\/12 {
    flex-basis: 50%;
  }

  .lg\:basis-7\/12 {
    flex-basis: 58.3333%;
  }

  .lg\:basis-8\/12 {
    flex-basis: 66.6667%;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:flex-wrap {
    flex-wrap: wrap;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:items-stretch {
    align-items: stretch;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:gap-10 {
    gap: 2.5rem;
  }

  .lg\:gap-12 {
    gap: 3rem;
  }

  .lg\:gap-16 {
    gap: 4rem;
  }

  .lg\:gap-2 {
    gap: .5rem;
  }

  .lg\:gap-20 {
    gap: 5rem;
  }

  .lg\:gap-4 {
    gap: 1rem;
  }

  .lg\:gap-8 {
    gap: 2rem;
  }

  .lg\:rounded-2xl {
    border-radius: 1rem;
  }

  .lg\:rounded-\[20px\] {
    border-radius: 20px;
  }

  .lg\:rounded-xl {
    border-radius: .75rem;
  }

  .lg\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .lg\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .lg\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .lg\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .lg\:border {
    border-width: 1px;
  }

  .lg\:border-2 {
    border-width: 2px;
  }

  .lg\:border-solid {
    border-style: solid;
  }

  .lg\:border-none {
    border-style: none;
  }

  .lg\:border-purple {
    --tw-border-opacity: 1;
    border-color: rgb(133 91 250 / var(--tw-border-opacity));
  }

  .lg\:bg-transparent {
    background-color: #0000;
  }

  .lg\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .lg\:bg-\[url\(\'\.\.\/src\/img\/backgrounds\/dna-vertical\.svg\'\)\] {
    background-image: url("dna-vertical.96dfe8e6.svg");
  }

  .lg\:bg-\[url\(\'\.\.\/src\/img\/elements\/purple-timeline\.svg\'\)\] {
    background-image: url("purple-timeline.7c6ac04e.svg");
  }

  .lg\:bg-\[url\(\'\.\.\/src\/img\/pictures\/banner-desktop-home-bebe\.jpeg\'\)\] {
    background-image: url("banner-desktop-home-bebe.e2bd195e.jpeg");
  }

  .lg\:bg-\[url\(\'\.\.\/src\/img\/pictures\/resultados-bebe-desktop\.jpg\'\)\] {
    background-image: url("resultados-bebe-desktop.bc53c25c.jpg");
  }

  .lg\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }

  .lg\:bg-none {
    background-image: none;
  }

  .lg\:from-\[\#865afb\] {
    --tw-gradient-from: #865afb var(--tw-gradient-from-position);
    --tw-gradient-to: #865afb00 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:to-\[\#9cabfd\] {
    --tw-gradient-to: #9cabfd var(--tw-gradient-to-position);
  }

  .lg\:bg-contain {
    background-size: contain;
  }

  .lg\:bg-\[left_1\.5rem_top_50\%\] {
    background-position: 1.5rem 50%;
  }

  .lg\:bg-\[left_2rem_top_50\%\] {
    background-position: 2rem 50%;
  }

  .lg\:bg-\[left_3rem_top_50\%\] {
    background-position: 3rem 50%;
  }

  .lg\:bg-right {
    background-position: 100%;
  }

  .lg\:bg-right-top {
    background-position: 100% 0;
  }

  .lg\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .lg\:object-cover {
    -o-object-fit: cover;
    object-fit: cover;
  }

  .lg\:object-\[calc\(50\%\+200px\)_50\%\] {
    -o-object-position: calc(50% + 200px) 50%;
    object-position: calc(50% + 200px) 50%;
  }

  .lg\:object-center {
    -o-object-position: center;
    object-position: center;
  }

  .lg\:object-top {
    -o-object-position: top;
    object-position: top;
  }

  .lg\:\!p-6 {
    padding: 1.5rem !important;
  }

  .lg\:\!p-8 {
    padding: 2rem !important;
  }

  .lg\:\!p-9 {
    padding: 2.25rem !important;
  }

  .lg\:p-0 {
    padding: 0;
  }

  .lg\:p-12 {
    padding: 3rem;
  }

  .lg\:p-4 {
    padding: 1rem;
  }

  .lg\:p-6 {
    padding: 1.5rem;
  }

  .lg\:p-8 {
    padding: 2rem;
  }

  .lg\:p-\[1\.5em_1em_1\.5em_5\.5em\] {
    padding: 1.5em 1em 1.5em 5.5em;
  }

  .lg\:p-\[1rem_1rem_1rem_5rem\] {
    padding: 1rem 1rem 1rem 5rem;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .lg\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:\!pb-6 {
    padding-bottom: 1.5rem !important;
  }

  .lg\:pb-16 {
    padding-bottom: 4rem;
  }

  .lg\:pb-20 {
    padding-bottom: 5rem;
  }

  .lg\:pl-12 {
    padding-left: 3rem;
  }

  .lg\:pl-14 {
    padding-left: 3.5rem;
  }

  .lg\:pl-32 {
    padding-left: 8rem;
  }

  .lg\:pl-8 {
    padding-left: 2rem;
  }

  .lg\:pr-4 {
    padding-right: 1rem;
  }

  .lg\:pt-0 {
    padding-top: 0;
  }

  .lg\:pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:pt-20 {
    padding-top: 5rem;
  }

  .lg\:pt-4 {
    padding-top: 1rem;
  }

  .lg\:pt-6 {
    padding-top: 1.5rem;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
  }

  .lg\:text-\[55px\] {
    font-size: 55px;
  }

  .lg\:text-\[80px\] {
    font-size: 80px;
  }

  .lg\:text-h2 {
    font-size: 2.5rem;
  }

  .lg\:text-h3 {
    font-size: 1.75rem;
  }

  .lg\:text-larger {
    font-size: 1.25rem;
  }

  .lg\:font-semibold {
    font-weight: 600;
  }

  .lg\:leading-10 {
    line-height: 2.5rem;
  }

  .lg\:leading-\[58px\] {
    line-height: 58px;
  }

  .lg\:leading-normal {
    line-height: 1.5;
  }

  .lg\:leading-tight {
    line-height: 1.25;
  }

  .lg\:text-graystronger {
    --tw-text-opacity: 1;
    color: rgb(169 169 169 / var(--tw-text-opacity));
  }

  .lg\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .lg\:shadow-base {
    --tw-shadow: 0 4px 8px #0003;
    --tw-shadow-colored: 0 4px 8px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:before\:absolute:before {
    content: var(--tw-content);
    position: absolute;
  }

  .lg\:before\:top-\[-20px\]:before {
    content: var(--tw-content);
    top: -20px;
  }

  .lg\:before\:top-\[-57\%\]:before {
    content: var(--tw-content);
    top: -57%;
  }

  .lg\:before\:h-\[40\]:before {
    content: var(--tw-content);
    height: 40px;
  }

  .lg\:before\:h-\[72\]:before {
    content: var(--tw-content);
    height: 72px;
  }

  .lg\:before\:w-\[40\]:before {
    content: var(--tw-content);
    width: 40px;
  }

  .lg\:before\:w-\[72\]:before {
    content: var(--tw-content);
    width: 72px;
  }

  .lg\:before\:bg-\[url\(\'\.\.\/src\/img\/icons\/i\.png\'\)\]:before {
    content: var(--tw-content);
    background-image: url("i.f730d31b.png");
  }

  .lg\:before\:bg-\[url\(\'\.\.\/src\/img\/icons\/resultados-medico\.png\'\)\]:before {
    content: var(--tw-content);
    background-image: url("resultados-medico.9e454bf4.png");
  }

  .lg\:before\:bg-\[url\(\'\.\.\/src\/img\/icons\/resultados-pais\.png\'\)\]:before {
    content: var(--tw-content);
    background-image: url("resultados-pais.6adbef65.png");
  }

  .lg\:before\:bg-\[url\(\'\.\.\/src\/img\/icons\/resultados-parceiro\.png\'\)\]:before {
    content: var(--tw-content);
    background-image: url("resultados-parceiro.14286391.png");
  }

  .lg\:before\:content-\[\'\'\]:before {
    --tw-content: "";
    content: var(--tw-content);
  }

  .lg\:after\:content-none:after {
    --tw-content: none;
    content: var(--tw-content);
  }
}

/*# sourceMappingURL=doencas-analisadas.66e01a8a.css.map */
